.m-popover {
  opacity: 1;
}
.m-popover .rc-tooltip-inner {
  background: white;
  color: #373737;
  border-radius: 4px;
  opacity: 1;
  padding: 0.5rem;
  box-shadow: 1px 7px 14px 1px rgb(65 69 88 / 20%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  /* border: 1px solid #bcc3cd61; */
}

.m-popover-full .rc-tooltip-inner {
  padding: 1px;
}

.m-popover .rc-tooltip-arrow{
  border-top-color: #ffffff;
}