.theme-vfc {
  --color-app-secondary: #fab03c;
  --color-app-secondary-light: #ffcb81;
  --color-app-primary: #80bb55;
  --color-app-blue-primary: #4979a7;
  --color-header-light: #d6e9ce;
  --color-bg-tabs: rgba(236, 237, 241, 1);
  &.on-ios {
    & .disabled {
      opacity: 0.8 !important;
    }
    & input:disabled,
    & textarea:disabled {
      opacity: 0.8 !important;
    }
  }
  &.wrap-page-arow {
    & .app-main__inner {
      padding: 20px 15px 0;
    }
    & .page-custom {
      --color-label-header: #4e7c29;
      --color-bg-header: #7fbb50;
      --color-bg-detail: #7fbb50;
      --shadow-detail: #659343 3px 3px 6px 0px inset,
        #679546 -3px -3px 6px 1px inset;
      --color-header-light: #d6e9ce;
      --color-highlight: #7fbb50;
      & .p-header {
        margin-bottom: 0px;
        padding: 10px 1rem;
        background-color: var(--color-bg-header);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 20px;
      }
      & .wrap-detail {
        box-shadow: var(--shadow-detail);
        background-color: var(--color-bg-detail);
        padding: 10px 1rem 1rem 1rem;
        & .table-bordered {
          & thead {
            background-color: var(--color-header-light);
          }
        }
        & .page-title-heading {
          color: #000000c4;
          font-weight: 600;
        }
      }
      & .wrap-master {
        & .m-header {
          border-bottom: 0;
          & .form-control {
            border-color: #ced4da;
            background-color: #fff !important;
            color: #495057;
          }
          & .form-group {
            & > label {
              color: #495057;
            }
          }
        }
        & .bold-control {
          & .form-control {
            font-weight: 600;
          }
          & .form-control:disabled,
          & .form-control.disabled {
            border-color: var(--color-header-light);
            background-color: var(--color-header-light) !important;
            color: #333;
          }
          &.form-group {
            & > label {
              color: var(--color-label-header);
            }
          }
        }
      }
    }
    & .all-control-inline {
      & .form-group {
        display: flex;
        & > label {
          width: 100px;
          height: calc(2.25rem + 2px);
          display: flex;
          align-items: center;
        }
        & > div {
          flex: 1;
        }
      }
    }
    & .control-inline {
      &.form-group {
        display: flex;
        & > label {
          width: 100px;
          height: calc(2.25rem + 2px);
          display: flex;
          align-items: center;
        }
        & > div {
          flex: 1;
        }
      }
    }
  }
  @media (max-width: 1199.98px) {
    & .popover {
      position: absolute !important;
      top: 0px !important;
      left: 0px !important;
      transform: translate(5%, 50%) !important;
    }
    & .app-header__menu {
      & .dropdown-menu {
        position: absolute !important;
        top: 0 !important;
        left: auto !important;
        right: auto;
        min-width: 10rem !important;
        transform: translate(0%, 45px) !important;
      }
    }
    &.on-page-arrangement {
      & .mpat-file_manager {
        & .mui-grid {
          grid-template-columns: 1fr 1fr 1fr !important;
        }
      }
    }
    & .modal-mobile-full {
      max-width: calc(100vw - 3.5rem);
    }
  }
  @media (max-width: 576px) {
    .app-header {
      position: fixed;
      width: 100%;
      z-index: 9;
    }
    .sidebar-mobile-overlay {
      z-index: 15;
    }
    .app-sidebar {
      top: 60px;
      z-index: 16;
    }
    .app-main__outer {
      margin-top: 60px;
    }
    .app-header__logo {
      padding: 0;
    }
    .app-header__mobile-menu {
      padding-right: 0px;
    }
    .app-header__menu {
      padding-left: 0px;
      & .dropdown-menu {
        transform: translate(calc(-0.42 * 15rem), 55px) !important;
      }
    }
    & .mpat-admintable_filter_popover {
      & .mtfcw-ap {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
    &.on-page-arrangement {
      & .mpat-file_manager {
        & .mui-grid {
          grid-template-columns: 1fr !important;
        }
      }
    }
    & .modal-mobile-full {
      max-width: calc(100vw - 1rem);
    }
    & .margin-top-3-576 {
      margin-top: 1rem !important;
    }
  }
  @media ((max-width: 767.98px)) {
    & .mpat-admintable_filter_popover {
      & .mtfcw-ap {
        min-width: auto;
      }
    }
  }
  & .mp-admin_tab {
    & .body-tabs {
      &.body-tabs-layout {
        background-color: var(--color-bg-tabs); //var(--color-app-secondary);
      }
      & .RRT__tab {
        color: #111;
        position: relative;
        font-weight: 600;
        margin-left: 0.25rem;
        margin-bottom: -1px;
        border-bottom: 3px solid transparent;
        transition: border 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
        height: 53px !important;
        &:first-child {
          margin-left: 0;
        }
      }
      & .RRT__tab--selected {
        border-bottom: 3px solid var(--color-app-blue-primary);
        color: #fff;
      }
    }
    & .RRT__inkbar {
      display: none;
    }
    & .RRT__tab--selected:before {
      background: var(--color-app-blue-primary) !important;
    }
    & .RRT__tab:before {
      background: rgba(214, 232, 207, 1); //var(--color-app-secondary-light);
      content: " ";
      color: white;
      border-radius: 5px;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0.55rem;
      bottom: 0.5rem;
      z-index: -1;
      min-height: 36px;
    }
    & .RRT__showmore {
      & .RRT__tab {
        margin-left: 0;
        margin-bottom: 0;
        height: auto !important;
        border-bottom: 0px;
        &:before {
          min-height: 0px;
          background: #fff;
        }
      }
      & .RRT__tab--selected {
        background: var(--color-app-blue-primary) !important;
        color: #fff !important;
      }
    }
  }
  & .page-title-heading {
    & > div {
      color: #111;
      font-weight: 600;
    }
  }
  & .react-bs-container-header > table {
    background-color: var(--color-header-light);
  }
  & .app-main__inner {
    padding: 20px 15px 0;
  }
  & .app-page-title {
    background: var(--color-app-primary);
  }
  & .mpat-admintable_filter_popover {
    & .aa-footer {
      & > div {
        margin-right: 0 !important;
      }
    }
  }
  & .page-eqqd {
    padding-bottom: 30px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  & .page-custom,
  & .page-eqqd,
  & .modal-page-eqqd,
  & .page-history-action,
  & .modal-page-history-action {
    --color-highlight: #1bb4c4;
    --color-primary-light: #e6effc;
    --color-primary: #033c73;
    --color-primary-light-40: rgba(3, 60, 115, 0.41);
    --color-primary-light-10: rgba(3, 60, 115, 0.1);
    --color-dark: #11111f;
    --color-bg-label: #f6f6f6;
    --color-grey-light: #838383;
    --color-bg-progress: #f4f4f4;
    --color-bg-progress-header: #e9e9e9;
    --color-bg-progress-bar: #d9d9d9;
    --color-divider: #eeeeee;
    --color-blue-light: #d9ecfe;
    --color-add-new: #039c24;
    --color-text-info: #3498db;
    --box-card: 0px 7px 14px 0px rgba(65, 69, 88, 0.1);
    --box-shadow-focus: inset 0 0 0 100px rgb(0 0 0/20%);
    --color-text-new: #0288d1;
    --color-border-blue: #9bd5f3;
    & .mc-select2 {
      & .css-yk16xz-control,
      & .css-1fhf3k1-control {
        border-color: #ced4da;
      }
    }
    & .form-control {
      height: calc(2.25rem + 2px);
    }
    & .r-card {
      padding: 1rem;
      box-shadow: var(--box-card);
      background-color: #fff;
      border-radius: 4px;
    }
    & .btn {
      &.btn-blue-oulined {
        color: #138bff;
        border-color: #138bff;
        background-color: #d9ecfe;
      }
    }
    & .btn {
      &.btn-text-primary {
        color: var(--color-primary);
        background-color: #fff;
        border: none;
        box-shadow: #0d6efd21 0px 4px 4px 0px;
        font-weight: 600;
      }
    }
    & .mfcw-v1 {
      display: flex;
      & .mfc-label {
        background-color: var(--color-bg-label);
        padding: 0.375rem 0.75rem;
        border: 1px solid var(--color-bg-label);
        height: calc(2.25rem + 2px);
        font-size: 0.88rem;
        line-height: 1.7;
        border-radius: 0.25rem 0 0 0.25rem;
        color: #4a4a4a;
      }
      & .mfc-ctr {
        flex: 1;
        & .form-control {
          border-color: var(--color-bg-label);
          border-radius: 0 0.25rem 0.25rem 0;
          &:focus {
            box-shadow: none;
            border-color: var(--color-bg-label);
          }
        }
        & .mfc-select2 {
          & .form-control > div {
            border-color: var(--color-bg-label);
            border-radius: 0 0.25rem 0.25rem 0;
            box-shadow: none;
          }
        }
      }
    }
    & .custom-style-table {
      & .mp-admin_table {
        & .card {
          box-shadow: none;
          position: unset;
          & .card-body {
            overflow: unset !important;
          }
          &.mb-3 {
            margin-bottom: 0 !important;
          }
        }
        & .card-body {
          padding: 0;
        }
        & .table-bordered {
          & th,
          & td {
            border-bottom: 0px;
          }
          & thead {
            border-top-color: transparent;
            & th {
              border-top-color: transparent;
              &:first-child {
                border-left-color: transparent;
              }
              &:last-child {
                border-right-color: transparent;
              }
            }
          }
          & tbody {
            & td {
              &:first-child {
                border-left-color: transparent;
              }
              &:last-child {
                border-right-color: transparent;
              }
            }
            & tr:last-child {
              & td {
                border-bottom: 1px solid transparent;
              }
            }
          }
        }
        & .react-bs-table-bordered {
          box-shadow: var(--box-card);
          border-radius: 0.25rem;
        }
      }
    }
    & .ct-check {
      display: flex;
      align-items: center;
      &.checkbox-label-first {
        flex-direction: row-reverse;
        & > label {
          width: fit-content;
          flex: unset;
          margin-right: 0.5rem;
        }
        & > div {
          flex: 1;
        }
      }
      & label {
        margin-left: 0 !important;
        margin-bottom: 0;
        flex: 1;
      }
      & .form-check-input {
        margin-top: 0 !important;
      }
      &.checkbox_first {
        margin-top: 0.25rem;
        & label {
          margin-left: 0.5rem !important;
          max-width: fit-content;
        }
      }
    }
    & .modal-body {
      & .mp-admin_table {
        padding-top: 1rem;
      }
    }
    &.qdl__add {
      & .mpat-table-list {
        max-height: 75vh;
        overflow-y: auto;
      }
    }
    & .form-lb-inline {
      & .form-group {
        display: flex;
        & > label {
          background-color: var(--color-bg-label);
          padding: 0.375rem 0.75rem;
          border: 1px solid var(--color-bg-label);
          height: calc(2.25rem + 2px);
          font-size: 0.88rem;
          line-height: 1.7;
          border-radius: 0.25rem 0 0 0.25rem;
          color: #4a4a4a;
        }
        & > div {
          flex: 1;
        }
        & .form-control {
          border-color: var(--color-bg-label);
          border-radius: 0 0.25rem 0.25rem 0 !important;
          &:focus {
            box-shadow: none;
            border-color: var(--color-bg-label);
          }
        }
      }
    }
    & .form-check-input {
      position: unset;
      margin-left: 0;
    }
    & .hightlight-control {
      & .mc-select2 {
        & > div {
          border-color: var(--color-highlight);
        }
      }
      & .form-control {
        border-color: var(--color-highlight);
      }
      & .mc_check_inbox {
        & .option-inbox {
          &.normal.is-checked {
            border-color: var(--color-highlight);
          }
          &.special.is-checked {
            & .title {
              background-color: var(--color-highlight);
            }
          }
        }
      }
      & .mc_check_yes_no {
        border-color: var(--color-highlight);
      }
      & .mc_readonly {
        & .__value {
          border-color: var(--color-highlight);
        }
      }
      &.ct-check {
        & > label {
          color: var(--color-highlight) !important;
        }
        & .form-check-input {
          border-color: var(--color-highlight) !important;
          &:checked {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='50px' height='50px'><path fill='none' stroke='%231bb4c4' stroke-linecap='line' stroke-linejoin='line' stroke-width='2' d='M6 10l3 3l6-6'/></svg>");
          }
        }
      }
    }
  }
  & .margin-bottom-0 {
    margin-bottom: 0rem !important;
  }
  & .margin-bottom-1 {
    margin-bottom: 0.25rem !important;
  }
  & .__msgerr,
  & .__msginf {
    margin: 0.25rem 0 0.5rem 0;
    & > svg {
      margin-top: 0.2rem !important;
    }
  }
  & .closed-sidebar {
    & .fixed_title {
      left: 80px;
      width: calc(100% - 80px);
    }
    &.closed-sidebar-mobile {
      & .fixed_title {
        left: 0;
        width: 100%;
      }
    }
  }
  & .mui-select_box {
    & .option {
      &:hover {
        background-color: #deebff;
      }
    }
  }
  & .__loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .padding-left-0 {
    padding-left: 0 !important;
  }
  & .padding-right-1 {
    padding-right: 0.25rem !important;
  }
  & .RRT__panel {
    & .page-history-action {
      & .app-page-title {
        margin: -16px -30px 10px;
      }
    }
  }
  /* arrangement custom*/
  &.on-page-arrangement {
    & .mpat-file_manager {
      & .a-header {
        margin-bottom: 0rem;
        padding-bottom: 0.5rem;
      }
      & .a-body {
        margin: 0 -1rem -1rem -1rem;
      }
      & .mui-file_manager_item {
        border-radius: 0.25rem;
        border: 0px;
        overflow: hidden;
        padding: 4px;
        box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
        & .a-openlink,
        & .a-delete {
          & .btn {
            border-radius: 50px;
            width: 30px;
            height: 30px;
            border-color: #f9f9f9;
          }
        }
        & .a-changeavatar {
          & .btn {
            border-radius: 50px;
            width: 30px;
            height: 30px;
            border-color: #f9f9f9;
          }
        }
        & .a-openlink {
          right: 4px;
          bottom: 4px;
          margin-right: 4px;
          margin-bottom: 4px;
          & button {
            background: #2196f3;
            &:hover {
              background: #177ccc;
            }
          }
          & i {
            margin-left: 2px;
          }
        }
        & .a-delete {
          top: 4px;
          right: 4px;
          & i {
            margin-bottom: 2px;
          }
        }
        & .a-photo {
          & img {
            border-radius: 0.25rem;
          }
        }
        & .a-info,
        & .a-name {
          & span {
            font-weight: 600;
            color: #555;
          }
        }
        &:hover,
        &:focus {
          box-shadow: #42a3f1 0px 2px 8px 0px;
          & .a-info,
          & .a-name {
            color: #033c73;
            & span {
              color: #033c73;
            }
          }
        }
      }
      & .mui-grid {
        padding: 1rem;
      }
    }
  }
  /* ck editor custom*/
  .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    flex-wrap: wrap;
  }
  & .pulse-ani {
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(47, 164, 231, 0.5);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(47, 164, 231, 0);
    }
  }
}
