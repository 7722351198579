.m-pa-full{
  position: absolute;
  overflow: auto;  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.m-dF-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-vH{
  visibility: hidden;
}
.m-dN{
  display: none;
}
.m-dN-i{
  display: none!important;
}

.m-grid-2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}
.m-grid-2colsw {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: min-content;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}

.ag-row-isloading{
  position: relative;
}
.ag-hide{
  display: none;
}
.ag-row-isloading > td.ag-cell-isloading{
  position: unset!important;
}
.ag-row-overlay{
  display: none;
}
.table-hover > tbody > tr.ag-row-isloading:hover{
  background-color: unset;
}
.ag-row-isloading > td.ag-cell-isloading .ag-row-overlay{
  position: absolute;
  inset: 0;
  overflow: hidden;
  /* background-color: rgba(255, 255, 255, 0.0); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(1px); */
  z-index: 1;
  cursor: wait;  
}

/* không dùng dc after vi no lam thay doi width cua cot */
/* .ag-row-isloading::after{
  position: absolute;
  content: 'count';
  background: red;
  left: 0;
  top: 0;
} */

@keyframes ani-addRow {
  0% {
    transform: scale(1, 0);
    line-height: 0px;
    background-color: #fff;
    visibility: collapse;
  }
  90% {
    background-color: #effaff;
    transform: scale(1, 1);
    line-height: 18px; /* font-size(16px) + border-top(1px) + border-bottom(1px) */
    visibility: visible;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes ani-deleteRow {
  0% {
    transform: scale(1, 1);
    line-height: 18px;
    background-color: #ffefef;
    visibility: visible;
    height: auto;
  }
  100% {
    background-color: #ffffff;
    transform: scale(1, 0);
    line-height: 0px; /* font-size(16px) + border-top(1px) + border-bottom(1px) */
    /* visibility: collapse; */
    height: 0px;
    display: none;
  }
}

@keyframes ani-waitingRow {
  0% {
    background-position: 0;
  }
  50% {
    background-position: -30%;
  }
  80% {
    background-position: -100%;
  }
  100% {
    background-position: -200%;
  }
}


table tr.ag-row-justNew {
  transition-duration: 0.2s;
  animation: ani-addRow 0.2s 1 linear;
  transform-origin: top;
  backface-visibility: hidden
}
table tr.ag-row-justDelete {
  transition-duration: 0.2s;
  animation: ani-deleteRow 0.2s 1 linear forwards;
  backface-visibility: hidden
}

table tr.ag-row-waiting {
  background-image: linear-gradient(to right, transparent 50%, rgba(0, 159, 255, .1) 50%);
  background-size: 200% 100%;
  animation: ani-waitingRow 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

table tr.ag-row-waiting td {
  opacity: .5;
  pointer-events: none;
}

table tr.ag-row-collapsed > td > div {
  max-height: 0!important;
  min-height: 0!important;
  padding: 0px!important;
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 0.3s, padding 0.3s;
}
table tr.ag-row-collapsed.aga-show > td > div {
  max-height: 1000px!important;
  transition: max-height 0.6s, padding 0.6s;
}