@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
// @import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";

// @import "node_modules/bootstrap/scss/mixins/border-radius";
// @import "node_modules/bootstrap/scss/mixins/text-truncate";
// @import "node_modules/bootstrap/scss/mixins/visually-hidden";
@import "./m.css";
@import "./big-calendar/styles.scss";

$list-group-border-color: #d8e2ef;
$list-group-action-hover-color: #5e6e82;
$list-group-hover-bg: #f9fafd;
$table-group-separator-color: #bdbdbd;

$primary: #6088b3; //#6088b3//mau truoc do: #3699ff
$info: $cyan;
$secondary: #e4e6ef;
$success: #4dbd74;
$warning: #ffa800;
$danger: #f64e60;
$light: #f3f6f9;
$dark: #131628;

$theme-colors: (
  "primary": $primary,
  "info": $info,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

:root {
  --color-primary: #{$primary};
  --color-info: #{$info};
  --color-secondary: #{$secondary};
  --color-success: #{$success};
  --color-warning: #{$warning};
  --color-danger: #{$danger};
  --color-light: #{$light};
  --color-dark: #{$dark};
  --color-text: #5e6e82;
  --color-bg-focus: #f7fbff;
  --color-placeholder: #c6c6c6;
}

@each $variant, $color in $theme-colors {
  .border-color-#{$variant} {
    border-color: $color !important;
  }
}

@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/utilities/api";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/progress";

@import "node_modules/bootstrap/scss/helpers/visually-hidden";
@import "node_modules/bootstrap/scss/helpers/text-truncation";

@import "./custom_reactstrap.scss";
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.card-header:first-child {
  border-radius: 0.3rem;
}
.btn-success,
.btn-success:active,
.btn-success:focus,
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-warning,
.btn-warning:active,
.btn-warning:focus,
.btn-danger,
.btn-danger:active,
.btn-danger:focus,
.btn-info,
.btn-info:active,
.btn-info:focus,
.btn-success:hover,
.btn-outline-success:hover,
.btn-primary:hover,
.btn-outline-primary:hover,
.btn-warning:hover,
.btn-outline-warning:hover,
.btn-danger:hover,
.btn-outline-danger:hover,
.btn-info:hover,
.btn-outline-info:hover {
  color: white;
}

// remove bg td
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: rgb(255 255 255 / 0%);
}

//remove default table-striped
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: none;
}

//bo sung striped khi co table expand
.table-has-expand
  > .react-bs-table
  > .react-bs-container-body
  > .table-striped
  > tbody
  > tr:nth-of-type(odd) {
  background-color: transparent;
}
.table-has-expand
  > .react-bs-table
  > .react-bs-container-body
  > .table-striped
  > tbody
  > tr:nth-of-type(4n-1) {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Text */
.text-ellipsis-l3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-ellipsis-l2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Modal */
.modal-body.a-modal-scrollable {
  max-height: calc(
    100vh - var(--bs-modal-margin) * 2 - var(--bs-modal-header-padding-y) * 2 -
      40px
  );
  overflow: auto;
}
.modal-body > .a-modal-scrollable {
  max-height: calc(
    100vh - var(--bs-modal-margin) * 2 - var(--bs-modal-header-padding-y) * 2 -
      40px - 2rem
  );
  overflow: auto;
}
